<template>
      <div class="device-testing-root">
            <div class="device-testing-card">
                <div ref="deviceTestingPrepare" class="device-testing-prepare">
                    <div class="testing-title">设备连接</div>
                    <div class="testing-prepare-info">设备检测前请务必给当前页面开放摄像头，麦克风权限哦~</div>
                    <div class="testing-prepare-error">
                        <span class="testing-prepare-error-color1">出现异常</span>
                        <span class="testing-prepare-error-color2">刷新页面或者根据操作手册调整设备</span>
                        <span class="testing-prepare-error-color3" @click="operationManual()">（操作手册）</span>
                    </div>
                    <div class="device-display">
                        <div ref="deviceCamera" class="device icon-normal">
                            <svg class="icon" aria-hidden="true">
                                <use xlink:href="#icon-cameraIcon"></use>
                            </svg>
                        </div>
                        <div ref="deviceVoice"  class="device icon-normal">
                            <svg class="icon" aria-hidden="true">
                                <use xlink:href="#icon-voice"></use>
                            </svg>
                        </div>
                        <div ref="deviceMic" class="device icon-normal">
                            <svg class="icon" aria-hidden="true">
                                <use xlink:href="#icon-microphone"></use>
                            </svg>
                        </div>
                        <div ref="deviceNetwork" class="device icon-normal">
                            <svg class="icon" aria-hidden="true">
                                <use xlink:href="#icon-network"></use>
                            </svg>
                        </div>
                    </div>
                    <div ref="deviceLoading" class="loading-background">
                        <div class="device-loading"></div>
                    </div>
                    <!-- 连接结果提示 -->
                    <div class="connect-info">
                        <!-- 连接结果 -->
                        <div ref="connectInfo" style="max-width: 60%;"></div>
                        <!-- 错误icon及错误解决指引 -->
                        <div ref="connectAttentionContainer" class="connect-attention-container" style="display: none;">
                            <div ref="connectAttentionIcon" class="connect-attention-icon">
                                <svg class="icon" aria-hidden="true">
                                    <use xlink:href="#icon-warn"></use>
                                </svg>
                            </div>
                            <div ref="connectAttentionInfo" class="connect-attention-info" style="display: none;">
                            </div>
                        </div>
                    </div>
                    <!-- 设备连接页面button -->
                    <div class="testing-btn-display">
                        <div ref="startTestBtn" @click="startCameraTesting()" class="check-btn-pc-primary start-test start-gray">开始检测</div>
                        <div ref="connectAgainBtn" @click="testingAgain()" class="check-btn-pc-primary connect-again" style="display: none;">重新连接</div>
                    </div>
                </div>

                <!-- 设备检测tab页 -->
                <div ref="deviceTesting" class="device-testing" style="display: none;">
                    <div class="device-testing-title">
                        <div ref="cameraTesting" class="testing icon-gray">
                            <svg class="icon" aria-hidden="true">
                                <use xlink:href="#icon-cameraIcon"></use>
                            </svg>
                        </div>
                        <div ref="voiceTesting" class="testing icon-gray">
                            <svg class="icon" aria-hidden="true">
                                <use xlink:href="#icon-voice"></use>
                            </svg>
                        </div>
                        <div ref="micTesting" class="testing icon-gray">
                            <svg class="icon" aria-hidden="true">
                                <use xlink:href="#icon-microphone"></use>
                            </svg>
                        </div>
                        <div ref="networkTesting" class="testing icon-gray">
                            <svg class="icon" aria-hidden="true">
                                <use xlink:href="#icon-network"></use>
                            </svg>
                        </div>
                    </div>
                    <!-- 设备检测-摄像头检测 -->
                    <div ref="cameraTestingBody" class="testing-body" style="display: none;">
                        <div class="device-list camera-device-list">
                            <div class="select-title" style="display: block;">摄像头选择</div>
                            <div class="select-list" style="display: block;">
                                <select name="select" ref="cameraSelect" class="device-select"></select>
                            </div>
                        </div>
                        <div ref="cameraVideo" class="camera-video" id="camera-video"></div>
                        <div class="testing-info-container">
                            <div class="testing-info">是否可以清楚的看到自己？</div>
                            <div class="button-list">
                                <div ref="cameraFail" @click="cameraCanSee(false)" class="fail-button check-btn-outline">看不到</div>
                                <div ref="cameraSuccess" @click="cameraCanSee(true)" class="success-button check-btn-pc-primary">可以看到</div>
                            </div>
                        </div>
                    </div>
                    <!-- 设备检测-播放器检测 -->
                    <div ref="voiceTestingBody" class="testing-body" style="display: none;">
                        <div class="device-list camera-device-list">
                            <div class="select-title" style="display: block;">扬声器选择</div>
                            <div class="select-list" style="display: block;">
                                <select name="select" ref="voiceSelect" class="device-select"></select>
                            </div>
                        </div>
                        <div class="audio-control">
                            <div class="audio-control-info">请调高设备音量, 点击播放下面的音频试试～</div>
                            <audio ref="audioPlayer" src="https://trtc-1252463788.cos.ap-guangzhou.myqcloud.com/web/assets/bgm-test.mp3" controls></audio>
                        </div>
                        <div class="testing-info-container">
                            <div class="testing-info">是否可以听到声音？</div>
                            <div class="button-list">
                                <div ref="voiceFail" @click="voiceCanHear(false)" class="fail-button check-btn-outline">听不到</div>
                                <div ref="voiceSuccess" @click="voiceCanHear(true)" class="success-button check-btn-pc-primary">可以听到</div>
                            </div>
                        </div>
                    </div>
                    <!-- 设备检测-麦克风检测 -->
                    <div ref="micTestingBody" class="testing-body" style="display: none;">
                        <div class="device-list camera-device-list">
                            <div class="select-title" style="display: block;">麦克风选择</div>
                            <div class="select-list" style="display: block;">
                                <select name="select" ref="micSelect" class="device-select"></select>
                            </div>
                        </div>
                        <div class="mic-testing-container">
                            <div class="mic-testing-info">对着麦克风说'哈喽'试试～</div>
                            <div ref="micBarContainer" class="mic-bar-container"></div>
                            <div ref="audioContainer"></div>
                        </div>
                        <div class="testing-info-container">
                            <div class="testing-info">是否可以看到音量图标跳动？</div>
                            <div class="button-list">
                                <div ref="micFail" @click="micCanSee(false)" class="fail-button check-btn-outline">看不到</div>
                                <div ref="micSuccess" @click="micCanSee(true)" class="success-button check-btn-pc-primary">可以看到</div>
                            </div>
                        </div>
                    </div>
                    <!-- 设备检测-硬件及网速检测 -->
                    <div ref="networkTestingBody" class="testing-body" style="display: none;">
                        <div class="testing-index-list">
                            <div class="testing-index-group">
                                <div class="testing-index">操作系统</div>
                                <div ref="system"></div>
                            </div>
                            <div class="testing-index-group">
                                <div class="testing-index">浏览器版本</div>
                                <div ref="browser"></div>
                            </div>
                            <!-- <div class="testing-index-group">
                                <div class="testing-index">IP地址</div>
                                <div ref="ip"></div>
                            </div> -->
                            <div class="testing-index-group">
                                <div class="testing-index">屏幕共享能力</div>
                                <div ref="screenShare"></div>
                            </div>
                            <div class="testing-index-group">
                                <div class="testing-index">网络质量</div>
                                <div ref="uplinkNetwork" class="network-loading"></div>
                            </div>
                        </div>
                        <div class="testing-footer">
                            <div ref="testingReportBtn" @click="viewTestReport()" class="check-btn-pc-primary">查看检测报告</div>
                        </div>
                    </div>
                </div>

                <!-- 设备检测报告 -->
                <div ref="deviceTestingReport" class="device-testing-report" style="display: none;">
                    <div class="testing-title">检测报告</div>
                    <!-- 检测报告内容 -->
                    <div class="device-report-list">
                        <!-- 摄像头报告信息 -->
                        <div class="device-report camera-report">
                            <div class="device-info">
                                <div class="report-icon">
                                    <svg class="icon" aria-hidden="true">
                                        <use xlink:href="#icon-cameraIcon"></use>
                                    </svg>
                                </div>
                                <div ref="cameraName" class="device-name"></div>
                            </div>
                            <div ref="cameraTestingResult" class="camera-testing-result"></div>
                        </div>
                        <!-- 扬声器报告信息 -->
                        <div ref="voiceReport" class="device-report voice-report">
                            <div class="device-info">
                                <div class="report-icon">
                                    <svg class="icon" aria-hidden="true">
                                        <use xlink:href="#icon-voice"></use>
                                    </svg>
                                </div>
                                <div ref="voiceName" class="device-name"></div>
                            </div>
                            <div ref="voiceTestingResult" class="voice-testing-result"></div>
                        </div>
                        <!-- 麦克风报告信息 -->
                        <div class="device-report mic-report">
                            <div class="device-info">
                                <div class="report-icon">
                                    <svg class="icon" aria-hidden="true">
                                        <use xlink:href="#icon-microphone"></use>
                                    </svg>
                                </div>
                                <div ref="micName" class="device-name"></div>
                            </div>
                            <div ref="micTestingResult" class="mic-testing-result"></div>
                        </div>
                        <!-- 网络报告信息 -->
                        <div class="device-report network-report">
                            <div class="device-info">
                                <div class="report-icon">
                                    <svg class="icon" aria-hidden="true">
                                        <use xlink:href="#icon-network"></use>
                                    </svg>
                                </div>
                                <div ref="networkName" class="device-name"></div>
                            </div>
                            <div ref="networkTestingResult" class="network-testing-result"></div>
                        </div>
                    </div>
                    <div class="device-report-footer">
                        <div ref="testing-again" @click="testingAgain()" class="device-report-btn check-btn-outline testing-agin">重新检测</div>
                        <div ref="testing-finish" @click="testingFinish()" class="check-btn-pc-primary testing-finish">完成检测</div>
                    </div>
                </div>

                <div @click="close(false)" ref="deviceTestingCloseBtn" class="device-testing-close-btn">
                    <svg class="icon" aria-hidden="true">
                        <use xlink:href="#icon-closeIcon"></use>
                    </svg>
                </div>
            </div>
      </div>
</template>
<script>
import {
    deviceTestingInit,
    startDeviceConnect,
    startCameraTesting,
    cameraCanSee,
    voiceCanHear,
    micCanSee,
    viewTestReport,
    finishDeviceTesting } from './check_device';
import './iconfont';
import baseUrl from '@/config/url'
import { exportMethod } from '../../../../../utils/download';
export default {
    props:{
        type: {
            type: String,
            default: ''
        }
    },
    name: "DeviceCheckDialog",
// const config = {
    data() {
        return {}
    },
    mounted() {
      console.log(this)
        this.deviceTestingInit(this);
        this.startDeviceConnect(this);
    },

    methods: {
        /**
         * @description 操作手册
         */
         operationManual(){
          console.log('operationManual');
          let myObj = {
                method: 'get',
                url: baseUrl.API+'/exam/v1/userExam/download',
                fileName: '学员考试操作手册.pdf',
            }
            exportMethod(myObj)
        },
        startDeviceConnect(vm) {
          startDeviceConnect(vm);
        },
        deviceTestingInit(vm) {
          deviceTestingInit(vm)
        },
        startCameraTesting() {
            this.$refs.deviceTestingPrepare.style.display = 'none';
            this.$refs.deviceTesting.style.display = '';
            startCameraTesting(this);
        },

        cameraCanSee(canSee) {
            cameraCanSee(canSee, this);
        },

        voiceCanHear(canHear) {
            voiceCanHear(canHear, this);
        },

        micCanSee(canSee) {
            micCanSee(canSee, this);
        },

        viewTestReport() {
            viewTestReport(this);
        },

        testingAgain() {
            if(this.$refs.deviceTestingReport){
                this.$refs.deviceTestingReport.style.display = 'none';
                this.$refs.deviceTestingPrepare.style.display = '';
            }

            console.log(this.$refs,'this.$refs.cameraVideothis.$refs.cameraVideo')
            if(this.$refs.cameraVideo){
                this.$refs.cameraVideo.style.display = 'none';
                this.$refs.cameraVideo.style.display = '';
            }
            this.$el.parentNode.removeChild(this.$refs.cameraVideo)

            startDeviceConnect(this);
        },

        testingFinish() {
            if(this.type=='restart'){
                this.$nextTick(()=>{
                    finishDeviceTesting(this);
                    this.$emit('closePc')
                    // this.$destroy();
                    // if (this.$el && this.$el.parentNode) {
                    //     this.$el.parentNode.removeChild(this.$el)
                    // }

                })
            }else {
                this.close(true);
            }
        },
        close(result) {
            finishDeviceTesting(this);
            this.$emit('close', result);
            this.$destroy();

            // if (this.$el && this.$el.parentNode) {
            //     this.$el.parentNode.removeChild(this.$el)
            // }
        }
    }
}
// }

// const DeviceCheckDialog = Vue.extend(config);

// export function openDeviceCheckDialog(roomId, changeState) {
//
//     const dialog = new DeviceCheckDialog({
//         propsData: {
//             roomId
//         }
//     });
//     console.log(dialog,'909090909090909')
//     dialog.$mount();
//
//     let wdc_app = document.getElementById('app-cms');
//
//     wdc_app.appendChild(dialog.$el)
//
//
//     changeState(() => {
//         dialog.close();
//     })
//
//     return {
//         close() {
//                 dialog.close();
//         },
//         afterClosed() {
//                 return new Promise((resolve) => {
//                     dialog.$on('close', (result) => {
//                             resolve(result);
//                     })
//                 });
//         }
//     }
// }

// export default config;

</script>
<style  scoped>
.icon {
    width: 1em; height: 1em;
    vertical-align: -0.15em;
    fill: currentColor;
    overflow: hidden;
 }

 button{
    margin: 0 !important;
    color: #fff !important;
    background-color: #006EFF !important
}

div{
    display: flex;
    align-items: center;
}

.device-testing-root {
      width: 100%;
      height: 100%;
      position: fixed;
      top: 0;
      left: 0;
      display: flex;
      justify-content: center;
      align-self: center;
      /* background: rgba(0, 0, 0, 0.1); */
      background: #F7FAFF;
      z-index: 100000000
}

.device-testing-card{
    width: 640px;
    height: 480px;
    background: #F7F7F7;
    border-radius: 10px;
    position: relative;
    display: block;
}
.device-testing-prepare,.device-testing-report{
    display: block;
}
.device-testing{
    flex-wrap: wrap;
}
.testing-title{
    font-size: 34px;
    justify-content: center;
    margin-top: 55px;
    color: #201e1ee5;
}
.testing-prepare-info{
    font-size: 16px;
    justify-content: center;
    margin-top: 25px;
    color: #585656e5;
}
.testing-prepare-error{
    font-size: 16px;
    justify-content: center;
    margin-top: 25px;
    color: #585656e5;
}
.testing-prepare-error-color1{
    font-size: 16px;
}
.testing-prepare-error-color2{
    font-size: 16px;
    color:red;
}
.testing-prepare-error-color3{
    font-size: 16px;
    color: rgb(45, 103, 255);
    cursor: pointer;
    text-decoration:underline
}
.device-testing-close-btn{
    width: 25px;
    height: 25px;
    position: absolute;
    top: 8px;
    right: 8px;
    cursor: pointer;
    font-size: 20px;
}
.device-display{
    margin-top: 40px;
    justify-content: center;
}
.device{
    width: 46px;
    height: 46px;
    position: relative;
    justify-content: center;
    font-size: 38px;
}
.device:not(:first-child){
    margin-left: 60px;
}
.device:not(:first-child).noVoiceDevice{
    margin-left: 100px;
}
.device::before{
    content: '';
    width: 28px;
    height: 28px;
    position: absolute;
    bottom: -34px;
    left: 50%;
    transform: translateX(-50%);
}
.connect-success::before{
    background: url('../../../asset/images/success.png') no-repeat;
    background-size: 100% 100%;
}
.connect-fail::before{
    background: url('../../../asset/images/fail.png') no-repeat;
    background-size: 100% 100%;
}
@keyframes device-loading {
    0%{
        width: 0%;
        border-radius: 6px 0 0 6px;
    }
    50%{
        width: 50%;
        border-radius: 6px 0 0 6px;
    }
    100%{
        width: 100%;
        border-radius: 6px;
    }
}
.loading-background{
    width: 350px;
    height: 3px;
    border-radius: 6px;
    margin: 20px auto 0;
    background: #bfbfbf;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
}
.device-loading{
    height: 3px;
    background-color: #808080;
    animation: device-loading 2s;
    animation-fill-mode: both;
}
.connect-info{
    margin-top: 60px;
    display: flex;
    height: 48px;
    justify-content: center;
}
.connect-attention-container{
    position: relative;
    margin-left: 3px;
}
.connect-attention-icon{
    font-size: 20px;
    color: red;
}
.connect-attention-info{
    padding: 8px 12px;
    min-width: 120px;
    min-height: 50px;
    background: rgba(0, 0, 0, 0.6);
    border-radius: 10px;
    color: #fff;
    position: absolute;
    right: 0;
    bottom: 100%;
    transform: translate(20px, -10px);
    display: block;
    white-space: nowrap;
    font-size: 12px;
}
.connect-attention-info::after{
    content: '';
    width: 0;
    height: 0;
    border: 10px transparent solid;
    border-top-color: rgba(0, 0, 0, 0.6);
    position: absolute;
    left: 100%;
    top: 100%;
    transform: translateX(-40px);
}
.testing-btn-display{
    justify-content: center;
    margin-top: 30px;
}
.check-btn-pc-primary{
    width: 200px;
    height: 44px;
    background: #006EFF;
    border-radius: 5px;
    text-align: center;
    color: #fff;
    justify-content: center;
    cursor: pointer;
}
.start-gray{
    background: #dddddd;
    color: #fff;
    cursor: not-allowed;
}
.device-testing-title{
    justify-content: center;
    width: 100%;
    margin-top: 40px;
}
.testing{
    width: 26px;
    height: 26px;
    position: relative;
    justify-content: center;
    text-align: center;
    font-size: 24px;
    line-height: 24px;
}
.testing:not(:first-child){
    margin-left: 90px;
}
.testing:not(:first-child)::before {
    content: '';
    width: 70px;
    height: 2px;
    position: absolute;
    top: 50%;
    left: -80px;
    background: #bfbfbf;
}
.testing:not(:first-child).noVoiceDevice{
    margin-left: 150px;
}
.testing:not(:first-child).noVoiceDevice::before{
    width: 130px;
    left: -140px;
}
.testing.complete {
    cursor: pointer;
}
.testing.complete:not(:first-child)::before {
    background: #006EFF;
}
.testing-body{
    width: 100%;
    flex-wrap: wrap;
    justify-content: center;
}
.device-list{
    margin: 30px auto 0;
    width: 100%;
    justify-content: center;
}
.device-select{
    width: 260px;
    height: 30px;
    margin-left: 20px;
    padding: 0 10px;
    border-radius: 4px;
    -webkit-appearance: menulist-button;
}
.camera-video{
    width: 300px;
    height: 180px;
    display: block;
    margin: 30px auto 0;
}
.testing-info-container{
    display: block;
    position: absolute;
    bottom: 50px;
    margin-top: 24px;
    left: 50%;
    transform: translateX(-50%);
}
.testing-info{
    width: 100%;
    text-align: center;
    display: block;
}
.button-list{
    margin-top: 20px;
    width: 500px;
    justify-content: space-between;
}
.fail-button{
    /* border: 1px solid #006EFF;
    border-radius: 8px;
    color: #006EFF;
    padding: 6px 14px;
    cursor: pointer; */
}
.check-btn-outline {
    width: 240px;
    height: 40px;
    display: block;
    background: transparent;
    font-size: 16px;
    color: #316FFF;
    border-radius: 10px;
    line-height: 40px;
    text-align: center;
    border: 1px solid #316FFF;
    border-radius: 6px;

}
.check-btn-pc-primary {
    width: 240px;
    height: 40px;
    line-height: 40px;
    display: block;
    text-align: center;
    background: #316FFF;
    font-size: 16px;
    color: #fff;
    border-radius: 6px;
}
/* .success-button{
    border: 1px solid #006EFF;
    border-radius: 8px;
    background: #006EFF;
    color: #fff;
    padding: 6px 14px;
    cursor: pointer;
} */
.audio-control{
    width: 320px;
    display: block;
    margin: 40px auto 0;
}
.audio-control-info{
    margin: 0px auto 20px 6px;
    color: #5f5f5f;
}
.mic-testing-container{
    display: block;
    margin: 30px auto 0;
}
.mic-testing-info{
    color: #bbbbbb;
    font-size: 14px;
}
.mic-bar-container{
    justify-content: center;
    margin-top: 10px;
}
.mic-bar{
    width: 10px;
    height: 30px;
    border: 1px solid #cccccc;
    border-radius: 1px;
}
.mic-bar:not(:first-child){
    margin-left: 3px;
}
.mic-bar.active{
    background: #006EFF;
}
.testing-index-list{
    margin-top: 40px;
    width: 100%;
    display: block;
}
.testing-index-group{
    width: 55%;
    display: flex;
    justify-content: space-between;
    margin-top: 14px;
    margin: 10px auto 0;
}
@keyframes loading-circle{
    0% {
        transform: rotate(0deg);
    }
    25% {
        transform: rotate(90deg);
    }
    50% {
        transform: rotate(180deg);
    }
    75% {
        transform: rotate(270deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
.network-loading::before{
    content: '';
    width: 16px;
    height: 16px;
    background: url('../../../asset/images/loading.png');
    background-size: 100% 100%;
    animation: loading-circle 2s linear infinite;
}
.testing-footer{
    margin-top: 70px;
    justify-content: center;
}
.device-report-list{
    display: block;
    margin-top: 40px;
}
.device-report{
    width: 60%;
    margin: 20px auto 0;
    justify-content: space-between;
}
.device-info{
    width: 85%;
}
.report-icon{
    width: 24px;
    height: 24px;
    margin-right: 20px;
    justify-content: center;
    font-size: 22px;
    line-height: 22px;
    color: #515151;
}
.device-name{
    width: 280px;
    height: 24px;
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.device-report-footer{
    margin-top: 50px;
    justify-content: space-between;
    margin-left: 72px;
    width: 500px;
}
.device-report-btn{
    /* width: 160px;
    height: 40px;
    border: 1px solid;
    border-radius: 6px;
    justify-content: center;
    cursor: pointer; */
}

.testing-agin{
    border-color: #006EFF;
    color: #006EFF;
}
.testing-finish{
    /* background: #006EFF;
    color: #fff;
    margin-left: 60px; */
}
</style>
