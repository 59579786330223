import axios from 'axios';
export function exportMethod(data,respType) {
    console.log(data,'data----')
    if(!respType){
        respType = "application/vnd.ms-excel";
    }
    axios({
        method: data.method,
        url: `${data.url}${data.params ? '?' + data.params : ''}`,
        responseType: 'blob',
        data:data.data,
        headers:{
            organ:localStorage.getItem('organ'),
            token:localStorage.getItem('token'),
            actor:localStorage.getItem('actor'),
            channel:localStorage.getItem("channel"),
            branch:localStorage.getItem("branch")
        },
        params:data.queryParams? data.queryParams:null
    }).then((res) => {
        const link = document.createElement('a')
        let blob = new Blob([res.data], {type: 'application/vnd.ms-excel'});
        let fileName = '';
        if(res.headers["content-disposition"]){
            let temp = res.headers["content-disposition"].split(";")[1].split("filename=")[1];
            //对文件名乱码转义--【Node.js】使用iconv-lite解决中文乱码
            let iconv = require('iconv-lite');
            iconv.skipDecodeWarning = true;//忽略警告
            fileName = iconv.decode(temp, 'utf-8');
        }
        link.style.display = 'none'
        link.href = URL.createObjectURL(blob)
        link.download = fileName ? fileName : data.fileName;
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
    }).catch(() => {
        // this.$message.error({
        //     title: '错误',
        //     desc: '网络连接错误'
        // })
    })
}
